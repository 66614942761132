import { useTranslation } from "react-i18next";
import { z } from "zod";

const PARAMETERS_SEPARATOR = ";;";

export function useZodErrorWithParameters(error: string | undefined) {
	const { t } = useTranslation();
	let errorTranslation = undefined;
	if (!error) {
		return { errorTranslation };
	}

	if (error.includes(PARAMETERS_SEPARATOR)) {
		const parameters = error.split(PARAMETERS_SEPARATOR);
		const values = JSON.parse(parameters[1]) as
			| Record<string, string>
			| undefined;
		errorTranslation = t(parameters[0], values);
	} else {
		errorTranslation = t(error);
	}
	return { errorTranslation };
}

export const intWithinRange = (min: number, max: number) => {
	return z.coerce
		.number({ invalid_type_error: "wrongValueType" })
		.int()
		.refine(
			(val) => {
				return val >= min && val <= max;
			},
			{
				message: getWithinRangeErrorMessage(min, max),
			},
		);
};

// eslint-disable-next-line custom-rules/prefer-zod-trimmed-email
export const trimmedEmail = z
	.string()
	.email("invalidEmail")
	.trim()
	.toLowerCase();

export function getWithinRangeErrorMessage(min: number, max: number) {
	return `valueMustBeBetween${PARAMETERS_SEPARATOR}${JSON.stringify({ min, max })}`;
}

/**
 * Zod validator that coerces the string "true" to the boolean true.
 * Useful for z.union of checkbox values where the value is "true" or undefined.
 */
export const trueString = z.preprocess((val) => {
	return val === "true" ? true : val;
}, z.literal(true));
